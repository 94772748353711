
export enum SymbolsList {
    "empty" = "",
    "uuid" = "pwabot_uuid",
    "pwaid" = "pwabot_pwaid",
    "acid" = "pwabot_acid",
    "uuidGet" = "user_id",
    "hasInstalled" = "has_installed_cookie",
    "fcmCookie" = "fcm_cookie",
    "pwaActive" = "active",
    "osAndroid" = "Android",
    "fbc" = "_fbc",
    "fbp" = "_fbp",
    "bbg" = "_bbg",
    "bbgCookieKey" = "bbg",
    "initialHref" = "initialHref",
    "offer" = "pwabot_offer",
    "offerStab" = "offer",
    "cloakStab" = "cloak",
    "urlStab" = "url",
    "applyUrl" = "pwabot_apply_url",
    "iosUrl" = "pwabot_ios_url",
    "desktopUrl" = "pwabot_desk_url",
    "osWindows" = "Windows",
    "osMac" = "Mac OS",
    "storepage" = "storepage",
    "snapchat" = "Snapchat",
}