import {useEffect} from "react";


const defineManifest = (pwaData) => {
    // const manifestObject = await (await fetch("source/json/manifest.json")).json();
    const manifestObject = {
        "short_name": pwaData?.appName,
        "name": pwaData?.appName,
        "description": pwaData.appDescription.length > 200 ?
            pwaData?.appDescription?.substr(0, 200) + "..." :
            pwaData?.appDescription,
        // "icons": [48, 72, 96, 192, 512, 48, 72, 96, 192, 512].map((dimension, index) => ({
        // "icons": [512].map(dimension => ({
        "icons": [48, 72, 96, 512, 144, 192, 48, 72, 96, 144, 192, 512].map((dimension, index) => ({
            "src": pwaData?.appIcon?.substr(0, pwaData?.appIcon?.lastIndexOf("/"))  +
                `/w=${dimension},h=${dimension},fit=crop`,
            "sizes": `${dimension}x${dimension}`,
            "type": "image/png",
            // "purpose": index > 4 ? "maskable" : "any",
            // "purpose": "maskable any",
            "purpose": index > 5 ? "maskable" : "any",
        })),
        "screenshots": pwaData?.richUi ? pwaData?.appScreenshots?.filter(f => f).map((src, index) => ({
            "src": src?.substr(0, src?.lastIndexOf("/")) + "/w=512,h=512,fit=crop",
            "sizes": "512x512",
            "type": "image/png",
            "form_factor": index % 2 ? "wide" : "narrow",
        })) : [],
        "start_url": window.location.href,
        // "start_url": "/",
        "scope": window.location.origin,
        "display": "standalone",
        "display_mode": "standalone",
        "theme_color": "#000000",
        "background_color": "#ffffff",
        "url": window.location.href,
        "version_code": "1",
        "version_name": "1.0",
        "prefer_related_applications": true,
        "related_applications": [
            {
                "platform": "webapp",
                "url": window.location.origin + "/source/json/manifest.json",
            },
        ],
        "intent_filters": {
            "scope_url_scheme": "https",
            "scope_url_host": window.location.hostname,
            "scope_url_path": "/"
        },
        "bound_webapk": {
            "runtime_host": "org.chromium.chrome",
            "runtime_host_application_name": "Chromium"
        },
        "manifest_package": "org.chromium.webapk." + pwaData?.id,
        "id": pwaData?.id,
        "web_manifest_url": window.location.origin + "/source/json/manifest.json",
    };

    manifestObject.scope = window.location.origin;
    // console.log("manifestObject", manifestObject);
    const manifestLink = window.document.getElementById("dynamic-manifest");
    const manifestString = JSON.stringify(manifestObject);
    const manifestBlob = new Blob([manifestString], {type: "application/json"});
    const manifestUrl = URL.createObjectURL(manifestBlob);
    manifestLink.setAttribute("href", manifestUrl);
};

const setStaticFile = () => {

    const manifestLink = window.document.getElementById("dynamic-manifest");
    manifestLink.setAttribute("href", "source/json/manifest.json")
};

export const useDynamicManifest = pwaData => {
    useEffect(() => {
        if (pwaData) {
            if (pwaData?.isDynamicManifest) {
                defineManifest(pwaData);
            } else {
                setStaticFile();
            }
        }
        // console.log("RerouteWrapper", "window.location.href", window.location.href);
    }, [pwaData]);
};
